/*
 * Polyfills
 */
import "./polyfills/object-fit";

/*
 * Modules
 */
import "./modules/header";
import "./modules/btn-hover";
import "./modules/equalize";
import "./modules/slider";
import "./modules/services";
import "./modules/tiles";
import "./modules/nav";
import "./modules/iframe-magnific";
import "./modules/filter";
import "./modules/parallax-custom";
import "./modules/aos-animations";
import "./modules/products-quantity";

/* FacetWP get current AJAX var/page */
$(document).ready(function () {
    document.addEventListener("facetwp-loaded", function () {
        //console.log(FWP_HTTP.get["_path1_categories"]);
        //Path 1 options
        if (
            FWP_HTTP.get["_path1_categories"] ==
            "part-1-am-i-at-risk-of-bone-loss"
        ) {
            $("#results h2").html("Am I at risk for bone loss?");
        }
        if (
            FWP_HTTP.get["_path1_categories"] ==
            "part-2-i-have-low-bone-density-osteopenia"
        ) {
            $("#results h2").html("I have low bone density (osteopenia)");
        }
        if (
            FWP_HTTP.get["_path1_categories"] ==
            "part-3-what-can-i-do-to-prevent-bone-loss-and-brok"
        ) {
            $("#results h2").html(
                "What can I do to prevent bone loss and broken bones?"
            );
        }
        if (
            FWP_HTTP.get["_path1_categories"] ==
            "part-4-who-can-help-me-create-an-action-plan"
        ) {
            $("#results h2").html("Who can help me create an action plan?");
        }

        //Path 2 options
        if (
            FWP_HTTP.get["_path2_categories"] ==
            "2-part-1-i-just-learned-i-have-osteoporosis-i-brok"
        ) {
            $("#results h2").html("What does this mean for me?");
        }
        if (
            FWP_HTTP.get["_path2_categories"] ==
            "part-2-what-can-i-do-to-prevent-further-bone-loss-"
        ) {
            $("#results h2").html(
                "What can I do to prevent further bone loss and broken bones?"
            );
        }
        if (FWP_HTTP.get["_path2_categories"] == "part-3-what-can-be-done") {
            $("#results h2").html("What can be done?");
        }
        if (
            FWP_HTTP.get["_path2_categories"] ==
            "part-4-who-can-help-me-create-a-plan"
        ) {
            $("#results h2").html("Who can help me create an action plan?");
        }
        //Path 3 options
        if (
            FWP_HTTP.get["_path3_categories"] ==
            "part-1-how-can-i-take-charge-of-my-osteoporosis-th"
        ) {
            $("#results h2").html(
                "How can I take charge of my osteoporosis through the years?"
            );
        }
        if (
            FWP_HTTP.get["_path3_categories"] ==
            "part-2-ive-had-a-broken-bone-fracture-whats-next"
        ) {
            $("#results h2").html(
                "I've had a broken bone (fracture) - what's next?"
            );
        }
        if (
            FWP_HTTP.get["_path3_categories"] ==
            "part-3-what-can-i-do-to-prevent-further-bone-loss-"
        ) {
            $("#results h2").html(
                "What can I do to prevent further bone loss and broken bones?"
            );
        }
        if (
            FWP_HTTP.get["_path3_categories"] ==
            "part-4-is-my-treatment-plan-working"
        ) {
            $("#results h2").html("Is my treatment plan working?");
        }
    });
});
