import "slick-carousel";

const $slider = $(".js-slider .slider__slides");

$slider.slick({
	slidesToShow: 3,
	slidesToScroll: 1,
	arrows: true,
	dots: false,
	infinite: true,
	autoplay: true,
	speed: 500,
	fade: false,
	cssEase: "linear",
});
